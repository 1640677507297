<template>
  <div 
    v-if="respondent" 
    class="
      active-respondent 
      grid items-center grid-cols-[50%_36%_auto] gap-[20px] 
      rounded-[50px] border border-[#dfe3e6] bg-[#ffffff] 
      px-[24px] py-[10px] mb-[10px] 
      transition-all hover:bg-[#f8f8f8]

      md:relative
      md:grid-cols-[100%]
      md:rounded-[10px]
      md:px-[16px] py-[16px]
    "
    :class="{
      'error': !validate,
    }"
  > 
    <div class="inline-grid grid-cols-[48px_auto] items-center gap-[20px] text-15 md:pr-[20px]">
      <div 
        class="border border-2 rounded-full transition-all"
        :style="{
          'border-color': currentRole?.roleId ? getColor(props.mode) : 'transparent',
          'box-shadow': currentRole?.roleId ? `-1px 1px 3px ${getColor(props.mode)}` : 'none',
        }"
      > 
        <RespondentPhoto :photo-url="props.respondent.photoUrl" />
      </div>
      <div ref="respondentFullName" class="min-md:max-h-[48px] min-md:overflow-hidden">
        <span v-if="!originalRespondentName">
          {{ props.respondent.fullName }}
        </span>
        <span 
          v-else
          v-dompurify-html="originalRespondentName"
          class="text-overflow"
        />
      </div>
    </div>

    <div>
      <Dropdown 
        v-if="allowChange && respondentRoles.length > 1"
        v-model="currentRole"
        :options="respondentRoles"
        option-label="label"
        class="w-full big"
        @change="onChangeRole"
      />
      <span v-else class="block text-gray text-14 leading-[1.4] md:px-0">
        {{ currentRole?.label ?? '-.-' }}
      </span>
    </div>
    <div class="md:absolute md:top-[12px] md:right-[16px]">
      <div v-if="allowChange" class="hide-block transition-all">
        <span class="cross cursor-pointer" @click="removeCheckedRespondent"></span>
      </div>
      <Icon v-else name="lock" class="w-[16px] text-[#7e7e80] mx-[4px] my-[4px]" />
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useActions, useGetters, useMutations } from '@/store/useStore'
import { useFindCoupleRole, useVerify, useStringFirstSpaceTransposition } from '@/use/useHelpers'

import Dropdown from 'primevue/dropdown'
import Icon from '@/components/UI/Icon.vue'
import RespondentPhoto from '@/components/UI/RespondentPhoto.vue'

const props = defineProps({
  allRespondents: Array,
  respondent: Object,
  respondentRoles: Object,
  respondentId: String,
  validate: Boolean,
  currentRespondentId: String,
  mode: {
    type: String,
    required: true,
  },
})

const {
  getRelations,
  getAllRoles,
  getColor,
} = useGetters()

const {
  setToggleCheckedRespondent,
} = useMutations()

const {
  fetchAddRelation,
  fetchDropRelation,
} = useActions()

const isPageVerify = useVerify()

const currentRole = ref(null)

const respondentFullName = ref(null)

const originalRespondentName = ref(null)

const allowChange = computed(() => {
  const targetRespondent = props.allRespondents.find(respondent => respondent.respondentId === props.respondentId)
  if (targetRespondent) return targetRespondent?.allowChange ?? true
  return true
})

const respondentRoles = computed(() => {
  const showSelfEvaluation = props.respondentId === props.currentRespondentId

  return getAllRoles.value.filter(({ roleId, selfEvaluation, localizedAttributes }) => {
    if (!localizedAttributes?.isSelectionDisabled) {
      if (roleId) return selfEvaluation === showSelfEvaluation
      else return true
    }
  })
})

const removeCheckedRespondent = () => {
  const selectObject = {} 
  selectObject[props.respondentId] = currentRole.value.roleId
  emit('change', selectObject)

  if (!currentRole.value.roleId) {
    setToggleCheckedRespondent({ respondentId: props.respondentId, checked: false, mode: props.mode })
  } else {
    // TODO: Возможно, setToggleCheckedRespondent и fetchDropRelation надо объединить
    fetchDropRelation([
      props.currentRespondentId,
      props.respondentId,
      
      props.mode,
      isPageVerify,
    ])

    setToggleCheckedRespondent({
      respondentId: props.respondentId,
      checked: false,
      mode: props.mode,
    })
  }
}
const emit = defineEmits(['change']);

const onChangeRole = () => {
  const selectObject = {} 
  selectObject[props.respondentId] = currentRole.value.roleId
  emit('change', selectObject)
  
  if (currentRole.value.roleId) {
    fetchAddRelation([
      props.currentRespondentId,
      useFindCoupleRole(currentRole.value, getRelations.value),
      
      props.respondentId,
      currentRole.value.roleId,
      
      props.mode,
      isPageVerify,
    ])
  } else {
    fetchDropRelation([
      props.currentRespondentId,
      props.respondentId,

      props.mode,
      isPageVerify,
    ])
  }
}

watch(() => respondentFullName.value, () => {
  if (props.respondent?.fullName) {
    const fullNameHeight = respondentFullName.value.offsetHeight
    const realFullNameHeight = respondentFullName.value.querySelector('span').offsetHeight

    if (fullNameHeight < realFullNameHeight) {
      originalRespondentName.value = useStringFirstSpaceTransposition(props.respondent.fullName, true)
    }
  }
})

onMounted(() => {
  const targetRespondent = props.allRespondents.find(({ respondentId }) => respondentId === props.respondentId)
  const targetRoleId = targetRespondent[`${props.mode.toLowerCase()}RoleId`]

  currentRole.value = getAllRoles.value.find(({ roleId }) => roleId === targetRoleId)
})
</script>

<!-- TODO: возможно, tailwind так умеет, но я не нашел -->
<style scoped lang="scss">
  @media all and (min-width: 769px) {
    .hide-block {
      visibility: hidden;
    }
    .active-respondent:hover {
      .hide-block {
        visibility: visible;
      }
    }

    .text-overflow::v-deep {
      span {
        overflow: hidden;
        white-space: nowrap;
        display: block;
        width: 100%;
        text-overflow: ellipsis;
      }
    }
  }

  .error .error {
    border: 1px solid red;
  }
</style>