<template>
<div 
  class="absolute transition-all inset-x-0 bottom-[100%] text-13 p-[15px] md:text-12 md:px-0 min-md:!hidden" 
  :class="{
    'md:!hidden': !opened,
  }"
  :style="{
    'background-color': getSurveySetting('panelSuccessBg'),
    'color': getSurveySetting('panelSuccessText'),
    'bottom': opened ? '100%' : '-200%',
  }"
>
  <div class="container">
    <div class="text-center">
      <div 
        v-dompurify-html="getSurveyText('textSuccessPanel')"
        class="text-success-panel text-24 font-medium md:pr-[20px] md:text-16"
      />
    </div>
  </div>

  <div class="absolute right-[35px] top-1/2 -translate-y-1/2 md:right-[20px] md:top-[16px] md:translate-y-0">
    <i class="pi pi-times !block cursor-pointer text-16" @click="opened = false" />
  </div>
</div>  
</template>

<script setup>
import { ref } from 'vue'
import { useGetters } from '@/store/useStore'

import Icon from '@/components/UI/Icon.vue'

const {
  getSurveyText,
  getSurveySetting,
} = useGetters()

const opened = ref(true)
</script>

<style scoped lang="scss">
  .text-success-panel::v-deep {
    span {
      font-weight: bold;
      border-bottom: 1px solid;
    }
  }
</style>
