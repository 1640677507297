<template>
  <Layout>
    <template #body>
      <div class="flex items-center justify-center h-full">
        <div class="container text-center py-[60px] md:py-[20px]">
          <img 
            v-if="getAttributesReady && !getSurveySetting('imageStartHide')" 
            :src="imageStart" 
            class="w-full max-w-[220px] mx-auto mb-[20px] md:w-auto md:max-w-[180px] md:max-h-[180px]" 
          />
          <div 
            v-if="getSurveyText('textStartHead') !== '-.-'"
            v-dompurify-html="getSurveyText('textStartHead')"
            class="text-darkgray text-24 font-medium mb-[10px] md:text-18 md:mb-[5px]" 
          />

          <div 
            v-if="getSurveyText('textStartDescription1') !== '-.-'"
            v-dompurify-html="getSurveyText('textStartDescription1')"
            class="text-gray mb-[32px] text-14 md:mb-[16px]"
          />

          <div v-if="getAttributesReady && getCurrentRespondent" class="inline-block mb-[32px] md:mb-[16px]">
            <div class="h-[1px] bg-[#d6d6d8]" />
            <div class="grid gap-[20px] grid-cols-[100px_auto] items-center p-[20px] md:text-14 md:px-0 md:py-[10px]">
              <PhotoUploader :photo-url="getCurrentRespondent.photoUrl" />
              <div class="text-left">
                <div class="text-gray text-14">{{ getSurveyText('textEntered') }}</div>
                <div 
                  v-dompurify-html="fullNameWithTransposition"
                  class="text-18 leading-[1.3] font-medium my-[6px]"
                />
                <div class="text-gray text-14">{{ getCurrentRespondent.email }}</div>
              </div>
            </div>
            <div class="h-[1px] bg-[#d6d6d8]" />
          </div>

          <div 
            v-if="getSurveyText('textStartDescription2') !== '-.-'"
            v-dompurify-html="getSurveyText('textStartDescription2')"
            class="max-w-[670px] w-full text-gray mb-[32px] mx-auto text-14"
          />
          <div class="flex items-center justify-center md:inline-flex md:flex-col">
            <router-link
              :to="{
                name: nextRoute,
              }" 
              :style="{ 
                'background-color': getColor('evaluating'),
                'color': getColorContrast('evaluating'),
              }"
              class="button mx-[10px] md:w-full"
            >
              {{ getSurveyText('textStartButtonText') }}
            </router-link>
          </div>
        </div>
      </div>
    </template>
    <template #header>
      <PageHeader />
    </template>
  </Layout>
</template>

<script setup>
import { computed, onMounted } from 'vue'

import { useGetters } from '@/store/useStore'
import { useRouter } from 'vue-router'
import { useStringFirstSpaceTransposition } from '@/use/useHelpers'

import imageStartSvg from '@/assets/images/imageStart.svg'

import Layout from '@/components/layout/Layout.vue'
import PageHeader from '@/components/layout/PageHeader.vue'
import PhotoUploader from '@/components/UI/PhotoUploader.vue'

const router = useRouter()

const {
  getSurveyText,
  getSurveySetting,
  getAttributesReady,
  getCurrentRespondent,
  getSteps,
  getColor,
  getColorContrast,
  getChooseCompleted,
} = useGetters()

const imageStart = computed(() => getSurveySetting.value('imageStart') ? getSurveySetting.value('imageStart') : imageStartSvg)

const nextRoute = computed(() => getSteps.value.evaluating ? 'evaluating' : 'evaluated')

const fullNameWithTransposition = computed(() => useStringFirstSpaceTransposition(getCurrentRespondent.value?.fullName ?? ''))

onMounted(() => {
  if (getChooseCompleted.value) {
    router.push({ name: 'check' })
  }
}) 
</script>
