<template>
  <div v-if="opened" class="fixed inset-0 z-50">
    <div class="absolute inset-0 backdrop-blur-md bg-[#000000]/50" />

    <div 
      class="
        absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-[#ffffff] 
        rounded-md shadow-[0_0_5px_rgba(87,87,87)] 
        max-w-[700px] w-full px-[60px] 
        md:min-w-[auto] md:max-w-[480px] md:w-full md:px-[20px]
      "
    >
      <div class="grid grid-cols-[auto_256px] overflow-hidden pt-[40px] md:block">
        <div class="text-gray pb-[40px]">
          <div class="font-medium text-36 mb-[18px]">{{ getSurveyText('textSuccessTitle') }}</div>
          <div class="text-14" v-dompurify-html="getSurveyText('textSuccess1')" />
          <div class="text-14 mt-[20px]" v-dompurify-html="getSurveyText('textSuccess2')" />
          <button 
            class="button mt-[36px]" 
            :style="{ 
              'background-color': getColor('evaluating'),
              'color': getColorContrast('evaluating'),
            }"
            @click="closeModal"
          >
            {{ getSurveyText('textSuccessButtonText') }}
          </button>
        </div>
        <img v-if="getSurveySetting('imageManGood')" :src="getSurveySetting('imageManGood')" />
        <IconMan v-else name="man-good" class="-mb-[4px] md:hidden" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'

import { useActions, useGetters } from '@/store/useStore'

import IconMan from '@/components/UI/IconMan.vue'

const {
  getAttributesReady,
  getChooseCompleted,
  getColor,
  getColorContrast,
  getSurveyText,
  getSurveySetting,
} = useGetters()

const {
  fetchSetChoosingCompleted,
} = useActions()

const opened = ref(false)

const watchingProps = computed(() => {
  return { 
    getAttributesReady: getAttributesReady.value, 
    getChooseCompleted: getChooseCompleted.value,
  }
})

const closeModal = async () => {
  await fetchSetChoosingCompleted(true)
  opened.value = false
  // TODO check cookies
}

watch(() => watchingProps.value, () => {
  if (watchingProps.value.getAttributesReady && !watchingProps.value.getChooseCompleted) {
    opened.value = true
  }
}, { immediate: true, deep: true })
</script>
