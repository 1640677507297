<template>
  <div>
    <h1 
      v-if="computedTitle.length"
      class="text-darkgray text-24 font-medium md:text-18"
    >
      <template
        v-for="(elem, index) in computedTitle"
        :key="index"
      >
        <span v-if="elem.type === 'text'" v-dompurify-html="elem.content" class="special-color" />
        <span v-else-if="elem.type === '{фио}'" v-dompurify-html="currentRespondent?.fullName ?? '-.-'" class="special-color" />
        <div 
          v-else-if="elem.type === '{фото}'"
          class="inline-block align-middle w-[36px] h-[36px] rounded-full transition-all mb-[4px] md:mb-0"
          
        >
          <RespondentPhoto :photo-url="currentRespondent?.photoUrl ?? null" />
        </div>
        <RespondentsCounter 
          v-else-if="elem.type === '{счетчик}'"
          :count="getRespondents.length" 
          :bg-color="getColor(props.mode)"
          :color="getColorContrast(props.mode)"
        />
      </template>
    </h1>
    <h1 v-else class="text-darkgray text-24 font-medium md:text-18">
      <span v-dompurify-html="props.title" />
      <RespondentsCounter 
        :count="getRespondents.length" 
        :bg-color="getColor(props.mode)"
        :color="getColorContrast(props.mode)"
        class="ml-[15px] md:ml-[7px]"
      />
    </h1>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useGetters } from '@/store/useStore'
import { useVerify } from '@/use/useHelpers'

import RespondentsCounter from '@/components/UI/RespondentsCounter.vue'
import RespondentPhoto from '@/components/UI/RespondentPhoto.vue'

const props = defineProps({
  title: String,
  mode: {
    type: String,
    required: true,
  },
  currentRespondentId: String,
})

const isPageVerify = useVerify()

const {
  getColor,
  getColorContrast,
  getRespondentById,
  getRespondentsForVerification,
} = useGetters()

const getRespondents = useGetters()[`getRespondents${props.mode}`]
const currentRespondent = computed(() => {
  if (isPageVerify) {
    return getRespondentsForVerification.value.find(({ respondentId }) => respondentId === props.currentRespondentId)
  } else {
    return getRespondentById.value(props.currentRespondentId)
  }
})

const computedTitle = computed(() => {
  const fullNameIndex = {
    index: props.title.indexOf('{фио}'),
    regex: '{фио}',
  }
  
  const photoUrlIndex = {
    index: props.title.indexOf('{фото}'),
    regex: '{фото}',
  }

  const counterIndex = {
    index: props.title.indexOf('{счетчик}'),
    regex: '{счетчик}',
  }

  const indexes = [ fullNameIndex, photoUrlIndex, counterIndex ]
    .filter(({ index }) => index > -1)
    .sort((a, b) => a.index - b.index)

  const result = []

  indexes.forEach(index => {
    const array = result.length
      ? result[result.length - 1].content.split(index.regex)
      : props.title.split(index.regex)

    result.pop()

    result.push(
      {
        type: 'text',
        content: array[0],
      },
      {
        type: index.regex,
      },
      {
        type: 'text',
        content: array[1],
      },
    )
  })

  return result
})


// TODO: хак для scss
const color = computed(() => getColor.value(props.mode))
</script>

<style lang="scss" scoped>
.special-color {
  ::v-deep {
    span {
      position: relative;
      color: v-bind(color);
      padding-bottom: 6px;
    }
  }
}
</style>

<!-- 
Была задача с добавлением подчеркиваний текстам, потом решили подчеркивания убрать.
Оставляю этот after на случай, если подчеркивания решат опять вернуть обратно.
Нужно вставить внутри .special-color span.

&:after {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  height: 4px;
  background-color: v-bind(color);
} 
-->
