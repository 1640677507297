<template>
  <div class="
    container h-full grid grid-rows-[max-content_auto] py-[40px]
    md:py-[20px]
  ">
    <div class="min-md:grid min-md:items-center min-md:grid-cols-[auto_max-content] mb-[46px] md:mb-[20px]">
      <Title 
        :title="getSurveyText(`title${props.mode}`)" 
        :mode="props.mode"
        :current-respondent-id="props.currentRespondentId"
      />
      <div class="md:!hidden">
        <!-- TODO: тексты -->
        <a 
          v-if="isPageVerify && !getMinSelectedEmployeesRoles.length"
          href="#" 
          class="text-gray text-15 inline-flex items-center"
          @click.prevent="routerBack" 
        >
          <Icon name="back" class="w-[18px] mr-[5px]" />
          <span>{{ getSurveyText('backButtonText') }}</span>
        </a>
      </div>
    </div>

    <div class="relative">
      <div class="
        absolute inset-0 inline-grid grid-cols-2 gap-[36px] 
        md:relative md:block
      ">
        <StepSticky
          :title="getSurveyText(`textStep1${props.mode}`)"
          class="step-1 min-md:!hidden md:top-0"
        />
        <DepsAndRespsLeft 
          :mode="props.mode" 
          class="step-1-trigger"
        />

        <StepSticky
          :title="getSurveyText(`textStep2${props.mode}`)"
          :step="2"
          class="step-2 min-md:!hidden md:bottom-0"
        />
        <DepsAndRespsRight
          :mode="mode"
          :nextRoute="nextRoute"
          :current-respondent-id="currentRespondentId"
          class="step-2-trigger"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, computed } from 'vue'

import { useGetters, useMutations, useActions } from '@/store/useStore'
import { useScrollStepsController, useVerify } from '@/use/useHelpers'
import { onBeforeRouteLeave, useRouter, useRoute } from 'vue-router'

import StepSticky from '@/components/StepSticky.vue'
import DepsAndRespsLeft from '@/components/DepsAndRespsLeft.vue'
import DepsAndRespsRight from '@/components/DepsAndRespsRight.vue'
import Title from '@/components/UI/Title.vue'
import Icon from '@/components/UI/Icon.vue'

const props = defineProps({
  mode: {
    type: String,
    required: true,
  },
  nextRoute: {
    type: String,
    required: true,
  },
  currentRespondentId: String,
})

const router = useRouter()
const route = useRoute()

const isPageVerify = useVerify()

const {
  getSurveyText,
  getRespondentsForVerification,
  getAllRoles,
  getMinSelectedEmployeesRoles
} = useGetters()

const {
  setClearChildrenDepartmentsAndRespondents,
  setSearchQuery,
  setSearchRespondents,
} = useMutations()

const {
  fetchGetChoosingVerifyingRelations,
  fetchGetRespondentsForVerification,
} = useActions()

onBeforeRouteLeave(() => {
  setSearchQuery('')
  setSearchRespondents([])
  setClearChildrenDepartmentsAndRespondents()
})

const routerBack = () => {
  router.back()
}

onMounted(async () => {
  await fetchGetChoosingVerifyingRelations(props.currentRespondentId)

  if (isPageVerify && !getRespondentsForVerification.length) {
    await fetchGetRespondentsForVerification()
  }

  useScrollStepsController()
})
</script>
