<template>
  <Layout>
    <template #body>
      <div class="flex items-center justify-center h-full">
        <div class="container text-center py-[60px]">
          <img :src="kittySvg" class="w-full max-w-[220px] mx-auto mb-[20px] md:max-w-[180px]" />
          <div 
            v-dompurify-html="getSurveyText('textChooseNotEnabledTitle')"
            class="text-darkgray text-24 font-medium mb-[10px] md:text-18 md:mb-[5px]" 
          />
          <div 
            v-dompurify-html="getSurveyText('textChooseNotEnabledText')"
            class="text-gray mb-[32px] md:text-14" 
          />
        </div>
      </div>
    </template>
    <template #header>
      <PageHeader />
    </template>
  </Layout>
</template>

<script setup>
import { useGetters } from '@/store/useStore'

import Layout from '@/components/layout/Layout.vue'
import PageHeader from '@/components/layout/PageHeader.vue'

import kittySvg from '@/assets/images/kitty.svg'

const {
  getSurveyText,
} = useGetters()
</script>