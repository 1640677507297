<template>
  <div v-if="currentRespondent" class="w-full">
    <div 
      v-dompurify-html="title" 
      class="text-darkgray text-24 font-medium leading-[1.6] mb-[30px] md:leading-[1.8] md:text-18 md:mb-[20px] special-color title-redirect"
      @click="redirectToEvaluate($event, 'span')"
    />

    <div 
      v-if="respondents.length" 
      class="
        transition-all
        rounded-3xl shadow-[0_0_5px_rgba(205,205,205,1)] 
        bg-[#ffffff] 
        border-[2px]
        py-5 mt-[30px]
        md:mx-auto md:text-left
        md:mt-0
      "
      :class="{
        'opacity-50': getSelectedRespondentForVerification.verified
      }"
      :style="{
        'border-color': getColor(props.mode),
      }"
    >
      <ul role="list" class="pretty-scrollbar max-h-[500px] overflow-x-auto px-5">
        <li 
          v-for="role in rolesAndRespondents" :key="role.roleId"
          class="[&:not(:last-child)]:border-b [&:not(:last-child)]:border-[#e7e7e7] [&:not(:last-child)]:pb-[4px] [&:not(:last-child)]:mb-[16px]"
        >
          <div class="mb-[6px]">
            <div
              :style="{ 
                'background-color': role.selfEvaluation ? getColor(props.mode) : '#535353',
                'color': role.selfEvaluation ? getColorContrast(props.mode) : '#ffffff',
              }"
              class="inline-block text-[12px] px-[10px] py-[2px] rounded-[30px]"
            >
              {{ role.label }}:
            </div>
          </div>
          <RespondentCheck
            v-for="respondent in role.respondents"
            :key="respondent.respondentId"
            :respondent="respondent"
            :mode="props.mode"
          />
        </li>
      </ul>
    </div>
    <div class="flex justify-center my-[15px]">
      <router-link
        :to="{ 
          name: `${props.mode}${verifyRoute ? 'Verify' : ''}`,
          params: verifyRoute
        }"
        class="inline-flex items-center bg-transparent font-normal text-gray transition-all hover:text-darkgray"
      >
        <Icon class="mr-[10px]" name="edit" />
        <span>{{ getSurveyText('textChangeRespondentsButtonText') }}</span>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { computed, watch } from 'vue'
import { useGetters, useMutations } from '@/store/useStore'
import { useRouter } from 'vue-router'

import RespondentCheck from '@/components/RespondentCheck.vue'
import Icon from '@/components/UI/Icon.vue'

import { useRespondentsSortedByRoles } from '@/use/useHelpers'

const props = defineProps({
  respondents: Array,
  mode: {
    type: String,
    required: true,
  },
  currentRespondent: Object,
})

const router = useRouter()

const number = props.mode === 'evaluating' ? 1 : 2

const {
  getSurveyText,
  getColor,
  getColorContrast,
  getSelectedRespondentForVerification,
  getMinSelectedEmployeesRoles,
  getRespondentsEvaluating,
  getRespondentsEvaluated,
  getRoleById,
  getEvaluatingErrors,
  getEvaluatedErrors
} = useGetters()

const {
  setEvaluatingErrors,
  setEvaluatedErrors
} = useMutations()

const verifyRoute = computed(() => props.currentRespondent?.verify && props.currentRespondent?.respondentId ? { respondentId: props.currentRespondent.respondentId } : null)

const title = computed(() => getSurveyText.value(`textStep${number}Check`).replace('{счетчик}', props.respondents.length))

const rolesAndRespondents = computed(() => useRespondentsSortedByRoles(props.respondents, props.mode))

const minSelectedRespondentsValidateBeforeVerify = () => {
  const rolesCounter = new Map()
  const respondentGetter = props.mode === 'evaluating' ? getRespondentsEvaluating : getRespondentsEvaluated
  const errors = []
  let errorText

  respondentGetter.value.forEach(respondent => {
    const respondentRoleLabel = getRoleById.value(respondent[`${props.mode}RoleId`])[0].label

    if (rolesCounter.has(respondentRoleLabel)) {
      const countInRolesCounter = rolesCounter.get(respondentRoleLabel);
      rolesCounter.set(respondentRoleLabel, countInRolesCounter + 1);
    } else {
      rolesCounter.set(respondentRoleLabel, 1);
    }
  })

  getMinSelectedEmployeesRoles.value.forEach(role => {
    const minSelectedEmployeesNumber = role.localizedAttributes?.minSelectedEmployees

    if (!rolesCounter.has(role.label) || rolesCounter.get(role.label) < minSelectedEmployeesNumber) {
      const error = getSurveyText.value('textLimitErrorItem').replace('{число}', minSelectedEmployeesNumber).replace('{роль}', role.label)
      errors.push(error)
    } else return
  })
    
  if (props.mode === 'evaluating') {
    errorText = getSurveyText.value('textLimitEvaluatingBeforeVerify')
    errors.length ? setEvaluatingErrors([errorText, ...errors]) : setEvaluatingErrors([])
  } else {
    errorText = getSurveyText.value('textLimitEvaluatedBeforeVerify')
    errors.length ? setEvaluatedErrors([errorText, ...errors]) : setEvaluatedErrors([])
  }
}

watch(() => getRespondentsEvaluated.value, () => {
  minSelectedRespondentsValidateBeforeVerify()
}, { immediate: true, deep: true })

// TODO: хак для scss
const color = computed(() => getColor.value(props.mode))

const redirectToEvaluate = ($event, tagName) => {
  if (!tagName || (tagName && $event.target.tagName.toLowerCase() === tagName)) {
    router.push({
      name: `${props.mode}${verifyRoute.value ? 'Verify' : ''}`,
      params: verifyRoute,
    })
  }
}
</script>

<style lang="scss" scoped>
.special-color {
  ::v-deep {
    span {
      position: relative;
      color: v-bind(color);
      padding-bottom: 6px;

      &:after {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 10px;
        height: 4px;
        background-color: v-bind(color);
      } 
    }
  }

  &.title-redirect::v-deep {
    span {
      cursor: pointer;
    }
  }
}
</style>
