<template>
  <div 
    class="relative inline-block align-middle ml-[15px] md:!hidden"
    :class="{
      '!hidden': !opened,
    }"
  > 
    <Icon name="arrow" class="absolute -right-[45px] bottom-[13px] w-[70px]" :style="{ 'color': getSurveySetting('panelSuccessBg') }" />
    <Icon name="times" class="w-[14px] h-[14px]" :style="{ 'color': getSurveySetting('panelSuccessText') }" />
    <div 
      class="relative desktop-panel text-14 font-normal rounded-[12px] px-[8px] py-[4px]"
      :style="{
        'background-color': getSurveySetting('panelSuccessBg'),
        'color': getSurveySetting('panelSuccessText'),
      }"
      v-dompurify-html="getSurveyText('textSuccessPanel')"
    />
  </div>
  </template>
  
  <script setup>
  import { ref, computed } from 'vue'
  import { useGetters } from '@/store/useStore'
  
  import Icon from '@/components/UI/Icon.vue'
  
  const {
    getSurveyText,
    getSurveySetting,
  } = useGetters()
  
  const opened = ref(true)
  
  const panelSuccessBg = computed(() => getSurveySetting.value('panelSuccessBg') ?? '#919fa0')
  const panelSuccessText = computed(() => getSurveySetting.value('panelSuccessText') ?? '#ffffff')
  </script>
  
  <style scoped lang="scss">
    .text-success-panel::v-deep {
      span {
        font-weight: bold;
        border-bottom: 1px solid;
      }
    }
  
    .desktop-panel::v-deep {
      .times {
        font-family: 'primeicons';
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        display: inline-block;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
  
        &:before {
          content: "\e90b";
          --webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  
          border-radius: 50%;
          background-color: v-bind(panelSuccessText);
          color: v-bind(panelSuccessBg);
          font-weight: bold;
          font-size: 12px;
          width: 17px;
          line-height: 16px;
          display: inline-block;
          text-align: center;
          padding: 1px 0 0 0;
        }
      }
    }
  </style>
  