import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import { store } from "./store"

import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import VueCookies from "vue-cookies"
import { vue3Debounce } from "vue-debounce"
import PrimeVue from 'primevue/config'
import VueDOMPurifyHTML from 'vue-dompurify-html'

const app = createApp(App)

app.use(router)
app.use(store)
app.directive("debounce", vue3Debounce({ lock: true }))
app.use(Toast, {})
app.use(VueCookies, { expire: "30d" })
app.use(PrimeVue)
app.use(VueDOMPurifyHTML)
router.isReady().then(() => app.mount("#app"))
